.Details {
    width: 400px;
    height: 200px;
    background-color: white;
    margin-top: 40px;
    margin-left: 20px;
    border-radius: 20px;
    padding: 20px;
}

.Details h1 {
    font-size: 20px;
}

.details-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 200px;
}

.details-main h4 {
    font-weight: 300;
}

.details-main p {
    font-weight: 600;
}

.market-cap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pe-ratio {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}