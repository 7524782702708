* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  height: 100vh;
}

.navbar {
  padding: 20px;
  height: 9vh;
}