.FaceProfile {
    height: 50px;

    display: flex;
    align-items: center;

    cursor: pointer;
}

.avatar {
    height: 100%;
    background-color: #c1e5f5;

    border-radius: 50%;
    width: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar img {
    height: 80%;
}

.user-details {
    display: flex;
    flex-direction: column;

    margin-left: 10px;
}

.user-details h2 {
    font-size: 20px;
}

.user-details p {
    font-size: 12px;
    color: #BFBFBF;
}

@media screen and (max-width: 1300px) {
    .user-details h2 {
        font-size: 17px;
    }
}

@media screen and (max-width: 1000px) {
    .user-details {
        display: none;
    }

    .avatar {
        width: 40px;
        height: 40px;
    }
}