.CompanyBox {
    height: 200px;

    background-color: white;

    padding: 10px;

    border-radius: 0px;
    cursor: pointer;

    /* margin-left: 40px; */

    min-width: 370px;
}

.logo {
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo img {
    width: 50px;
}

.heading {
    height: 100%;
    width: 100%;
    text-align: end;
}

.heading h1 {
    font-size: 15px;
    margin-right: 20px;
}

.heading p {
    margin-top: 5px;
    font-size: 18px;
    margin-right: 20px;
}

.portfolio {
    flex-wrap: nowrap;
    display: flex;
    width: 100%;
    height: 70%;
    align-items: center;
    flex-shrink: 0;
}

.data {
    width: 42%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.data h3 {
    color: #96A0B5;
    font-size: 18px;
}

.data p {
    margin-top: 5px;
    font-size: 23px;
    font-weight: bold;
}

.graph {
    width: 58%;
    height: 100%;
    background-color: white;
}

.graph-chart {
    width: 100%;
    height: 100px;
}