.SidePage {
    display: flex;
    align-items: center;
    justify-content: center;
    
    height: 91vh;
    width: 100%;
}

.sidepage {
    width: 15%;
    height: 91vh;

    background-color: white;

    display: flex;
    flex-direction: column;

    justify-content: start;

    padding-left: 20px;
}

.menuPage {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

.menuPage h1 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
}

.home {
    width: 100%;
    height: 30px;

    display: flex;
    align-items: center;

    margin-top: 20px;

    cursor: pointer;
}

.home img {
    height: 15px;
    width: 15px;

    margin-right: 20px;
}

.home p {
    font-size: 15px;

    font-weight: 500;
}

.menuPage button {
    outline: none;
    border: 0;
    background-color: transparent;
}

@media screen and (max-width: 1300px) {
    .sidepage {
        width: 100%;
        align-items: center;
    }

    .SidePage {
        flex-direction: column;
        overflow-y: scroll;
    }
}