.BellIcon {
    background-color: #F4F5F9;
    padding: 5px;
    height: 50px;
    width: 50px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.BellIcon img {
    width: 20px;
}