.WalletPage {
    width: 85%;
    height: 100%;
    background-color: #F5F7F9;

    border-top-left-radius: 10px;

    padding: 10px;

    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    padding-bottom: 20px;
}

.WalletPage::-webkit-scrollbar {
    display: none;
}

.wallet {
    background-color: white;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border-radius: 20px;
}

.my-investments {
    margin-left: 20px;

    display: flex;
    align-items: center;

    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.my-investments::-webkit-scrollbar {
    display: none;
}

.my-investments-box {
    width: 300px;
    height: 200px;
    background-color: white;

    margin-right: 90px;
}

.my-investments-box button {
    outline: none;
    border: 0;
}