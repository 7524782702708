.MainPageRender {
    width: 85%;
    height: 100%;
    background-color: #F5F7F9;

    border-top-left-radius: 10px;

    padding: 10px;

    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    padding-bottom: 20px;
}

.MainPageRender::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 1300px) {
    .MainPageRender {
        width: 100%;
    }
}