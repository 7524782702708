.LeaderboardPage {
    width: 85%;
    height: 100%;
    background-color: #F5F7F9;

    border-top-left-radius: 10px;

    padding: 10px;

    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    padding-bottom: 20px;
}

.LeaderboardPage::-webkit-scrollbar {
    display: none;
}

.pinned-heading {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
}

.LeaderboardContents {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contents img {
    height: 40px;
}

.contents h2 {
    font-size: 20px;
    font-weight: 500;
}

.contents-1 {
    flex-basis: 10%;
    display: flex;
    justify-content: center;
}

.contents-2 {
    flex-basis: 40%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.contents-3 {
    flex-basis: 30%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.contents-4 {
    flex-basis: 20%;
    text-align: center;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1000px) {
    .contents h2 {
        font-size: 15px;
    }

    .contents-1 {
        display: none;
    }
}