.Stock {
    width: 100%;
    height: 100%;
    background-color: #F5F7F9;

    border-top-left-radius: 10px;

    padding: 10px;

    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    padding-bottom: 20px;
}

.Stock::-webkit-scrollbar {
    display: none;
}

.description-idea {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1000px) {
    .stock-watch {
        display: none;
    }
}